import PricingType from '../constants/PricingType';

export const normalizeVehicleInfo = (vehicleInfo) => ({
  yearId: vehicleInfo?.yearId || vehicleInfo?.YearId,
  makeId: vehicleInfo?.makeId || vehicleInfo?.MakeId,
  makeName: vehicleInfo?.makeName || vehicleInfo?.MakeName,
  modelId: vehicleInfo?.modelId || vehicleInfo?.ModelId,
  modelName: vehicleInfo?.modelName || vehicleInfo?.ModelName,
  modelMarketName: vehicleInfo?.modelMarketName || vehicleInfo?.ModelMarketName,
  trimId: vehicleInfo?.trimId || vehicleInfo?.TrimId,
  trimName: vehicleInfo?.trimName || vehicleInfo?.TrimName,
  vehicleId: vehicleInfo?.vehicleId || vehicleInfo?.VehicleId,
  chromeStyleIds: vehicleInfo?.chromeStyleIds || vehicleInfo?.ChromeStyleIds || [vehicleInfo?.ChromeStyleId],
  vinNumber: vehicleInfo?.vinNumber || vehicleInfo?.vin || vehicleInfo?.Vin
});

export const normalizeSaveMyCarVehicleInfo = (vehicleInfo, zipcode, typicalMileage, lp, condition, vehicleOptions) => {
  const saveMyCarVehicleInfo = normalizeVehicleInfo(vehicleInfo);
  saveMyCarVehicleInfo.lp = lp || null;
  saveMyCarVehicleInfo.condition = condition || null;
  saveMyCarVehicleInfo.vehicleOptions = vehicleOptions || null;
  saveMyCarVehicleInfo.mileage = typicalMileage?.toString() || null;

  //set knowns
  saveMyCarVehicleInfo.zipcode = zipcode;
  saveMyCarVehicleInfo.year = saveMyCarVehicleInfo.yearId?.toString();
  saveMyCarVehicleInfo.vin = saveMyCarVehicleInfo.vinNumber?.toString();

  delete saveMyCarVehicleInfo.modelMarketName;
  delete saveMyCarVehicleInfo.vinNumber;
  delete saveMyCarVehicleInfo.trimId;

  return saveMyCarVehicleInfo;
};

export const costIsValid = (cost) => {
  return cost?.low > 0 && cost?.high > 0;
};

export const normalizeSelectedService = (selectedService) => {
  if (!selectedService) {
    return selectedService;
  }

  const norm = {};

  // support dual pricing
  if (!(selectedService[PricingType.DEALER] || selectedService[PricingType.INDEPENDENT])) {
    // if only undetermined type of pricing, assume it is dealer pricing
    norm[PricingType.DEALER] = {};
    if (selectedService.costs?.length > 0) {
      norm[PricingType.DEALER].costs = selectedService.costs;
    }
    if (costIsValid(selectedService.totalCost)) {
      norm[PricingType.DEALER].totalEstimate = selectedService.totalCost;
    } else if (selectedService.valueLow > 0 && selectedService.valueHigh > 0) {
      norm[PricingType.DEALER].totalEstimate = {
        low: selectedService.valueLow,
        high: selectedService.valueHigh
      };
    }
  }
  if (!costIsValid(selectedService.totalEstimate)) {
    if (costIsValid(selectedService.totalCost)) {
      norm.totalEstimate = selectedService.totalCost;
    } else if (selectedService.valueLow > 0 && selectedService.valueHigh > 0) {
      norm.totalEstimate = {
        low: selectedService.valueLow,
        high: selectedService.valueHigh
      };
    }
  }

  // support old single pricing.
  if (!selectedService.costs) {
    if (norm[PricingType.DEALER]?.costs?.length > 0) {
      norm.costs = norm[PricingType.DEALER].costs;
    } else if (norm[PricingType.INDEPENDENT]?.costs?.length > 0) {
      norm.costs = norm[PricingType.INDEPENDENT].costs;
    }
  }

  if (!costIsValid(selectedService.totalCost) && costIsValid(norm.totalEstimate)) {
    norm.totalCost = norm.totalEstimate;
  }

  return { ...selectedService, ...norm };
};

export const formatNumber = (value, sign = '') => {
  if (!value && value !== 0) {
    return '';
  }
  const s = Math.floor(value).toString();
  const i = s.length % 3;
  const h = i > 0 ? s.slice(0, i) + (s.length > 3 ? ',' : '') : '';
  return sign + h + s.slice(i).replace(/(\d{3})(?=\d)/g, '$1,');
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})([0-9a-zA-Z]|)$/);
  if (match) {
    return [match[2], match[3], match[4]].join('');
  }
  return cleaned;
};

export const formatDisplayPhoneNumber = (phoneNumber) => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return cleaned;
};

export const validatePhoneNumber = (phoneNumber) => {
  return formatPhoneNumber(phoneNumber).length === 10;
};

export const titleCase = (str) =>
  str && str.toLowerCase().replace(/(^|\s)([a-z])/g, (searchValue) => searchValue.toUpperCase());

// Date manipulation ex: '2020-01-01' -> '01/01/2020'
export const formatDateMMDDYYYY = (date) => {
  const d = new Date(date);
  let month = `${d.getUTCMonth() + 1}`;
  let day = `${d.getUTCDate()}`;
  const year = d.getUTCFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [month, day, year].join('/');
};
